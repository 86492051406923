import React, { useCallback, useMemo, useRef, useState } from "react";
import { Error as KendoError } from "@progress/kendo-react-labels";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import {
  ErrorMessage,
  Field,
  FastField,
  Formik,
  FormikProps,
  FormikValues,
} from "formik";

import Log from "../Log";
import RiskCalculator from "./RiskCalculator";
import Links from "./Links";
import {
  FormRow,
  FormikInput,
  FormikTextArea,
  FormikDateLabel,
  FormikDropDown,
  FormikCheckbox,
  FormikCheckboxGroup,
  FormikDatePicker,
  Option,
} from "../common/formik/FormikWrappers";
import AMLCTFRiskCalculator from "./AMLCTFRiskCalculator/AMLCTFRiskCalculator";
import Notifications from "../Notifications";
import { Notification } from "../Notifications/types";
import AccountInformation from "../AccountInformation";
import Dialog from "../Dialog";
import Risk from "./Risk";

import styles from "./DDCForm.module.scss";

import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { AMLCTFBinaryOption, FormData } from "../../common/ddc/types";
import {
  formatAsDate,
  getErrorMessage,
  optionsToStrings,
} from "../../common/util";
import {
  allAmlCtfOptionsSelected,
  calculateDDDates,
  calculateNextReviewDate,
  ddcToRequest,
} from "../../common/ddc/util";
import { DDC_TYPE_OTHER_ID } from "../../common/constants";
import { PDFExport } from "@progress/kendo-react-pdf";
import logo from "../../assets/images/logo.svg";
import { formatFrequency } from "../AccountInformation/AccountInformation";
import NewAMLCTFRiskCalculator from "./AMLCTFRiskCalculator/NewAMLCTFRiskCalculator";

const today = new Date();
//@TODO Remove this once full solution is implemented, instead of this quick fix
const maxNextDDDate = new Date(new Date().setFullYear(today.getUTCFullYear() + 10));

const createUBOSchema = (hasRCA = false) => {
  const UBOSchema = yup.object().shape({
    id: yup.string(),
    name: yup.string().required(),
    nationality: yup.object().required(),
    countryOfTaxResidence: yup.object().required(),
    isOnSanctionList: yup.object().required(),
    isPEP: yup.object().required(),
    isRCA: hasRCA ? yup.object().required() : yup.object(),
    hasAdverseInformationRelatedToFinancialCrime: yup.object().required(),
    details: yup.string(),
  });
  return UBOSchema;
};

const createUBOListSchema = (isRequired: boolean, hasRCA = false) => {
  const uboSchema = createUBOSchema(hasRCA);
  return isRequired
    ? yup.array().of(uboSchema).min(1, "Required")
    : yup.array().of(yup.object());
};

// Validation schema depends on external param which determines
// if AML checkbox is active, and if it is, AML check validation
// is required.

const createSchema = (
  validateAMLConfirmation: boolean,
  amlCtfVersion = ""
  //isNewAmlCtfVersion = false
) => {
  const isNewAmlCtfVersion = amlCtfVersion !== "";
  const hasRCA = isNewAmlCtfVersion && amlCtfVersion !== "v1";

  return yup.object().shape({
    relationshipToOtherParties: yup.string().required("Required"),
    typeOfDd: yup.object().required("Required"),
    cooperationWithCounterparty: yup.object().required("Required"),
    recommendation: yup.object().required("Required"),
    additionalMeasures: yup.string().when("recommendation", {
      is: (recommendation) => isRecommendationWithConditions(recommendation),
      then: yup.string().required("Required").max(500, "Max 500 characters"),
      otherwise: yup.string().notRequired(),
    }),
    frequency: yup.object().required("Required"),
    dueDiligenceConducted: yup.boolean().oneOf([true], "Required"),
    worldCheckOneConducted: validateAMLConfirmation
      ? yup.boolean().oneOf([true], "Required")
      : yup.boolean(),
    links: yup.array().of(
      yup.object().shape({
        content: yup.string().required("Required"),
        url: yup.string().required("Required").url("Url is not valid"),
      })
    ),
    dueDiligenceDocuments: yup.array().min(1, "Required"),
    countryOfIncorporation: yup.object().required("Required"),

    exemptFromUBOIdentification: yup.object(),
    applicableCountryOfResidence: yup.object(),
    countryOfResidence: isNewAmlCtfVersion
      ? yup
          .object()
          .when(
            ["exemptFromUBOIdentification", "applicableCountryOfResidence"],
            {
              is: (
                exempt: AMLCTFBinaryOption,
                applicable: AMLCTFBinaryOption
              ) => exempt?.value === "yes" && applicable?.value === "yes",
              then: yup.object().required("Required"),
              otherwise: yup.object().notRequired(),
            }
          )
      : yup.object().required("Required"),
    UBO: yup.array().when("exemptFromUBOIdentification", {
      is: (exempt) => exempt?.value === "yes",
      then: createUBOListSchema(false, hasRCA),
      otherwise: createUBOListSchema(true, hasRCA),
    }),
    nationalityUBO: isNewAmlCtfVersion
      ? yup.object()
      : yup.object().required("Required"),
    countriesOfBusinessActivities: yup.array().required("Required"),
    amlCtfScore: yup
      .string()
      .nullable()
      .test("aml-ctf-risk-test", "Error", function (score) {
        const lowerCaseScore = score?.toLowerCase();
        return (
          lowerCaseScore === "a" ||
          lowerCaseScore === "b" ||
          lowerCaseScore === "c"
        );
      }),
    comments: yup
      .string()
      .max(5000, "Comments field is limited to 5000 characters"),
    reasonForRiskChange: yup
      .string()
      .max(1000, "Reason for risk change field is limited to 1000 characters"),
    submissionDate: yup
      .date()
      .typeError("Date is in invalid format")
      .required("Required"),
    nextDDDate: yup.date().typeError("Date is in invalid format"),
  });
};

// TODO Replace name compare with ID compare
const isRecommendationWithConditions = (recommendation: Option) => {
  return recommendation && recommendation.name === "Positive, with conditions";
};

const isOtherReviewDateSelected = (frequency: Option) => {
  return frequency && frequency.name === "Other";
};

const isWcoDisabled = (
  formDisabled: boolean,
  wcoDisabled: boolean,
  values: FormikValues,
  amlCtfVersion?: string
  //isNewAmlCtfVersion?: boolean
) => {
  //const amlCtfFilled = allAmlCtfOptionsSelected(values, isNewAmlCtfVersion);
  const amlCtfFilled = allAmlCtfOptionsSelected(values, amlCtfVersion);
  return formDisabled || wcoDisabled || !amlCtfFilled;
};

const areCommentsUpdated = (oldValue: string, newValue: string) => {
  return oldValue !== newValue;
};

const isDDCTypeOther = (ddcType: Option) => {
  return ddcType && ddcType.id === DDC_TYPE_OTHER_ID;
};

const DDCForm: React.FC<FormData> = ({
  initialValues,
  logItems,
  readOnly : readOnlyProp,
  showBack,
  showTitle,
  showCancel,
  showRevert,
  ddConfirmationState,
  amlConfirmationState,
  riskConfigTemplates,
  amlCtfOptions,
  options,
  ddcStatus,
  save,
  submit,
  cancel,
  revert
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showRevertDialog, setShowRevertDialog] = useState(false);
  const [showLeaveCommentDialog, setShowLeaveCommentDialog] = useState(false);
  const history = useHistory();

  const hideNotification = useCallback((notification: Notification) => {
    setNotifications((notifications) =>
      notifications.filter((n) => n.uuid !== notification.uuid)
    );
  }, []);

  const showNotification = useCallback(
    (notification: Notification) => {
      setNotifications((notifications) => [...notifications, notification]);
      setTimeout(() => {
        // TODO unsubscribe or use isMounted
        hideNotification(notification);
      }, 10000);
    },
    [hideNotification]
  );

  const cancelDDC = useCallback(
    (
      values: FormikValues,
      setSubmitting: (isSubmitting: boolean) => void,
      isNewAmlCtfVersion = false
    ) => {
      setSubmitting(true);
      const formSubmission = ddcToRequest(values, isNewAmlCtfVersion);
      typeof cancel === "function" &&
        cancel(formSubmission)
          .then(() => {
            history.replace("/home/ddcoverview");
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            showNotification({
              uuid: uuidv4(),
              type: "error",
              text: message,
            });
            setSubmitting(false);
          });
    },
    [history, showNotification, cancel]
  );

  const revertDDC = useCallback(
  (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    isNewAmlCtfVersion = false
  ) => {
    setSubmitting(true);
    const formSubmission = ddcToRequest(values, isNewAmlCtfVersion);
    typeof revert === "function" &&
      revert(formSubmission)
        .then(() => {
          history.replace("/home/ddcoverview");
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          showNotification({
            uuid: uuidv4(),
            type: "error",
            text: message,
          });
          setSubmitting(false);
        });
  },
  [history, showNotification, revert]
);

  const validateAMLConfirmation = amlConfirmationState === "active";

  // Calculate dates for the next DD, and sets
  // them as refs so that multiple components can
  // mutate them and they wont be recalculated on
  // DDC form render.
  const [
    minDate,
    yearAwayDate,
    twoYearsAwayDate,
    threeYearsAwayDate,
    fiveYearsAwayDate,
  ] = calculateDDDates(initialValues.submissionDate);
  // TODO Consider removing refs and instad calculate values on demand.
  // It might be a tad slower, but would probably make code more readable.
  const maxDDDate = useRef(maxNextDDDate);
  const minDDDate = useRef(minDate);
  const yearAwayDDDate = useRef(yearAwayDate);
  const twoYearsAwayDDDate = useRef(twoYearsAwayDate);
  const threeYearsAwayDDDate = useRef(threeYearsAwayDate);
  const fiveYearsAwayDDDate = useRef(fiveYearsAwayDate);

  // Set maximum submission date to today, and minimum to three
  // monthes in the past from today.
  const maxSubmissionDate = new Date();
  const minSubmissionDate = new Date();
  minSubmissionDate.setMonth(minSubmissionDate.getMonth() - 3);

  const services = useMemo(() => optionsToStrings(initialValues.services), [
    initialValues.services,
  ]);
  const acolinCounterparties = useMemo(
    () => optionsToStrings(initialValues.acolinCounterparties, "id"),
    [initialValues.acolinCounterparties]
  );
  const contracts = useMemo(() => optionsToStrings(initialValues.contracts), [
    initialValues.contracts,
  ]);

  const exportable = ddcStatus === "ACTIVE" || ddcStatus === "OUTDATED";
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const amlCtfVersion: string = amlCtfOptions?.version ?? "";
  const isNewAmlCtfVersion: boolean = amlCtfVersion !== "";

  return (
    <div className={styles.root}>
      {exportable && (
        <div id={"mainExport"} className={styles.exportContainer}>
          <PDFExport
            fileName={`DDC_${initialValues.counterpartyName
              .split(" ")
              .join("_")}.pdf`}
            paperSize="A4"
            ref={pdfExportComponent}
            scale={0.6}
            keepTogether={".pdf-export"}
            margin={"1cm"}
          >
            <img className={styles.logo} src={logo} alt={"logo"} />
            <Formik initialValues={initialValues} onSubmit={() => {}}>
              {(props: FormikProps<any>) => (
                <form>
                  <div className={"pdf-export"}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Due Diligence Checklist</CardTitle>
                      </CardHeader>
                      <CardBody className={styles.cardBody}>
                        <div className={styles.headerItem}>
                          <dt> DDC Status: </dt>
                          <dd> {ddcStatus} </dd>
                        </div>
                        <div className={styles.headerItem}>
                          <dt> Date of Creation: </dt>
                          <dd>
                            {" "}
                            {formatAsDate(
                              new Date(initialValues.submissionDate)
                            )}
                          </dd>
                        </div>
                        <div className={styles.headerItem}>
                          <dt> Export Date: </dt>
                          <dd> {formatAsDate(new Date())} </dd>
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  <div className={"pdf-export"}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Account Information </CardTitle>
                      </CardHeader>
                      <CardBody className={styles.cardBody}>
                        <div className={styles.headerItem}>
                          <dt> Counterparty Name: </dt>
                          <dd> {initialValues.counterpartyName} </dd>
                        </div>

                        <div className={styles.headerItem}>
                          <dt> ID Number: </dt>
                          <dd> {initialValues.counterpartyId} </dd>
                        </div>

                        <div className={styles.headerItem}>
                          <dt>Acolin Services:</dt>
                          {initialValues.services.length ? (
                            initialValues.services.map(
                              (service: any, index: number) => (
                                <dd key={index}>{service.name}</dd>
                              )
                            )
                          ) : (
                            <dd>N/A</dd>
                          )}
                        </div>

                        <div className={styles.headerItem}>
                          <dt>Contract Types:</dt>
                          {initialValues.contracts.length ? (
                            initialValues.contracts.map(
                              (contract: any, index: number) => {
                                const contractAcolinCounterparty =
                                  initialValues.acolinCounterparties[index] ??
                                  "N/A";
                                const contractItem = `${contract.name} - ${contractAcolinCounterparty}`;
                                return <dd key={index}> {contractItem} </dd>;
                              }
                            )
                          ) : (
                            <dd>N/A</dd>
                          )}
                        </div>

                        <div className={styles.headerItem}>
                          <dt> Domicile: </dt>
                          <dd> {initialValues.domicile} </dd>
                        </div>

                        <div className={styles.headerItem}>
                          <dt> Latest DD Frequency: </dt>
                          <dd>
                            {initialValues.frequency
                              ? formatFrequency(
                                  initialValues.latestFrequency?.name,
                                  initialValues.latestNextDDate
                                )
                              : "N/A"}
                          </dd>
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  <div className={"pdf-export"}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Due Diligence</CardTitle>
                      </CardHeader>
                      <CardBody className={styles.cardBody}>
                        <FormRow label="Type:">
                          <FastField
                            name="ddcType"
                            options={options.ddcTypes}
                            component={FormikDropDown}
                            disabled={true}
                          />
                        </FormRow>
                        <FormRow label="Domicile Group:">
                          <FastField
                            name="domicileGroup"
                            options={options.domicileGroups}
                            component={FormikDropDown}
                            disabled={true}
                          />
                        </FormRow>
                        <FormRow label="Checklist Type:">
                          <FastField
                            name="typeOfDd"
                            options={options.typesOfDD}
                            component={FormikDropDown}
                            disabled={true}
                          />
                        </FormRow>
                        <FormRow label="Relationship to Other Parties:">
                          <FastField
                            name="relationshipToOtherParties"
                            component={FormikInput}
                            placeholder={
                              "Other entities this party is related to"
                            }
                            disabled={true}
                          />
                        </FormRow>
                        <FormRow label="Documents Used:">
                          <FastField
                            name="dueDiligenceDocuments"
                            options={options.dueDiligenceDocuments}
                            component={FormikCheckboxGroup}
                            disabled={true}
                          />
                        </FormRow>
                        <FormRow label="Cooperation with Counterparty:">
                          <FastField
                            name="cooperationWithCounterparty"
                            options={options.cooperationsWithCounterparty}
                            component={FormikDropDown}
                            disabled={true}
                          />
                        </FormRow>
                      </CardBody>
                    </Card>
                  </div>

                  <div className={"pdf-export"}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Risk Scoring</CardTitle>
                      </CardHeader>
                      <CardBody>
                        {isDDCTypeOther(initialValues.ddcType) ? (
                          <Risk />
                        ) : (
                          <RiskCalculator
                            templates={riskConfigTemplates}
                            ddcTypeId={initialValues.ddcType.id}
                            domicileGroup={initialValues.domicileGroup.id}
                            formikProps={props}
                            disabled={true}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </div>
                  <div className={"pdf-export"}>
                    <Card>
                      <CardHeader>
                        <CardTitle>AML/CTF</CardTitle>
                      </CardHeader>
                      <CardBody>
                        {isNewAmlCtfVersion ? (
                          <NewAMLCTFRiskCalculator
                            amlCtfOptions={amlCtfOptions}
                            formikProps={props}
                            readOnly={readOnlyProp}
                            //newUBOList={}
                          />
                        ) : (
                          <AMLCTFRiskCalculator
                            amlCtfOptions={amlCtfOptions}
                            formikProps={props}
                            readOnly={readOnlyProp}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </div>

                  <div className={"pdf-export"}>
                    <Card>
                      <CardHeader>
                        <CardTitle>Evaluation and Recommendation</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className={styles.headerItem}>
                          <dt> Comments: </dt>
                          <dd className={styles.exportTextArea}>
                            {" "}
                            {initialValues.comments}{" "}
                          </dd>
                        </div>
                        <br />
                        <div className={styles.headerItem}>
                          <dt> Reason for Change of Overall Raiting: </dt>
                          <dd className={styles.exportTextArea}>
                            {" "}
                            {initialValues.reasonForRiskChange}{" "}
                          </dd>
                        </div>
                        <br />

                        <FormRow label="Recommendation by the Compliance Team:">
                          <FastField
                            name="recommendation"
                            options={options.recommendations}
                            component={FormikDropDown}
                            disabled={true}
                          />
                        </FormRow>
                        {isRecommendationWithConditions(
                          initialValues.recommendation
                        ) && (
                          <div className={styles.headerItem}>
                            <dt> Additional measures:</dt>
                            <dd className={styles.exportTextArea}>
                              {initialValues.additionalMeasures}
                            </dd>
                          </div>
                        )}
                        <FormRow label="Reporting Date:">
                          <Field
                            name="submissionDate"
                            component={FormikDatePicker}
                            min={minSubmissionDate}
                            max={maxSubmissionDate}
                            disabled={true}
                          />
                        </FormRow>
                        <FormRow label="Next Review Due:">
                          <FastField
                            name="frequency"
                            options={options.frequencies}
                            component={FormikDropDown}
                            disabled={true}
                          />
                        </FormRow>
                        {isOtherReviewDateSelected(initialValues.frequency) && (
                          <FormRow label="Due Date:">
                            <Field
                              name="nextDDDate"
                              component={FormikDatePicker}
                              min={minDDDate.current}
                              max={fiveYearsAwayDDDate.current}
                              disabled={true}
                            />
                          </FormRow>
                        )}
                        <FormRow label="Ongoing DD:">
                          <Field
                            name="nextDDDate"
                            format="quartal"
                            component={FormikDateLabel}
                          />
                        </FormRow>
                      </CardBody>
                    </Card>
                  </div>
                </form>
              )}
            </Formik>
          </PDFExport>
        </div>
      )}
      {showTitle && (
        <div className={styles.header}>
          <h1>Due Diligence Checklist</h1>
          {showBack && (
            <div className={styles.back} onClick={() => history.goBack()}>
              Go Back
            </div>
          )}
        </div>
      )}
      {exportable && (
        <div className={styles.exportButtonContainer}>
          <Button
            className={styles.exportButton}
            type="button"
            onClick={() => {
              if (pdfExportComponent.current) {
                pdfExportComponent.current.save();
              }
            }}
          >
            Print
          </Button>
        </div>
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          // TODO consider passing a callback function from the
          // container parent and pass values as param
          //const formSubmission = ddcToRequest(values, isNewAmlCtfVersion);
          const formSubmission = ddcToRequest(values, amlCtfVersion);
          submit(formSubmission)
            .then(() => {
              history.replace("/home/ddcoverview");
            })
            .catch((error) => {
              console.log(error);
              const message = getErrorMessage(error);
              showNotification({
                uuid: uuidv4(),
                type: "error",
                text: message,
              });
              setSubmitting(false);
            });
        }}
        validationSchema={createSchema(
          validateAMLConfirmation,
          amlCtfVersion
          //isNewAmlCtfVersion
        )}
      >
        {(props: FormikProps<any>) => {
          const readOnly = props.isSubmitting || isSaving || readOnlyProp;
          return (
          <form
            className={styles.form}
            onSubmit={(e) => {
              if (!props.isValid) {
                setTimeout(() => {
                  showNotification({
                    uuid: uuidv4(),
                    type: "warning",
                    text: "One or more fields are not valid.",
                  });
                });
              }
              props.handleSubmit(e);
            }}
          >
            {logItems.length > 0 && (
              <Card>
                <CardBody className={styles.cardBody}>
                  <Log
                    items={logItems}
                    showStatus
                    amlCtfVersion={amlCtfVersion}
                    //isNewAmlCtfVersion={isNewAmlCtfVersion}
                  />
                </CardBody>
              </Card>
            )}
            <AccountInformation
              counterpartyName={props.values.counterpartyName}
              counterpartyId={props.values.counterpartyId}
              domicile={props.values.domicile}
              services={services}
              acolinCounterparties={acolinCounterparties}
              classification={props.values.classification}
              contracts={contracts}
              frequency={props.values.latestFrequency?.name}
              nextDDDate={props.values.latestNextDDDate}
              terminationDate={props.values.terminationDate}
              status={props.values.status}
            />
            <Card>
              <CardHeader>
                <CardTitle>Due Diligence</CardTitle>
              </CardHeader>
              <CardBody className={styles.cardBody}>
                <FormRow label="Type:">
                  <FastField
                    name="ddcType"
                    options={options.ddcTypes}
                    component={FormikDropDown}
                    sideEffect={(oldValue: Option, newValue: Option) => {
                      if (oldValue.id !== newValue.id) {
                        // Reset risk calculator category values
                        // when ddc type is changed.
                        ["O", "A", "L", "S", "RM", "C", "D"].forEach(
                          (fieldName: string) => {
                            props.setFieldValue(fieldName, "", false);
                            props.setFieldTouched(fieldName, false, false);
                          }
                        );
                        // Reset ARR
                        props.setFieldValue("arr", 0, false);
                        props.setFieldTouched("arr", false, false);
                      }
                    }}
                    disabled={readOnly}
                  />
                </FormRow>
                <FormRow label="Domicile Group:">
                  <FastField
                    name="domicileGroup"
                    options={options.domicileGroups}
                    component={FormikDropDown}
                    sideEffect={(oldValue: Option, newValue: Option) => {
                      if (oldValue.id !== newValue.id) {
                        // Reset risk calculator category values
                        // when ddc type is changed.
                        ["O", "A", "L", "S", "RM", "C", "D"].forEach(
                          (fieldName: string) => {
                            props.setFieldValue(fieldName, "", false);
                            props.setFieldTouched(fieldName, false, false);
                          }
                        );
                        // Reset ARR
                        props.setFieldValue("arr", 0, false);
                        props.setFieldTouched("arr", false, false);
                      }
                    }}
                    disabled={readOnly}
                  />
                </FormRow>
                <FormRow label="Checklist Type:">
                  <FastField
                    name="typeOfDd"
                    options={options.typesOfDD}
                    component={FormikDropDown}
                    disabled={readOnly}
                  />
                </FormRow>
                <FormRow label="Relationship to Other Parties:">
                  <FastField
                    name="relationshipToOtherParties"
                    component={FormikInput}
                    placeholder={"Other entities this party is related to"}
                    disabled={readOnly}
                  />
                </FormRow>
                <FormRow label="Documents Used:">
                  <FastField
                    name="dueDiligenceDocuments"
                    options={options.dueDiligenceDocuments}
                    component={FormikCheckboxGroup}
                    disabled={readOnly}
                  />
                </FormRow>
                <FormRow label="Cooperation with Counterparty:">
                  <FastField
                    name="cooperationWithCounterparty"
                    options={options.cooperationsWithCounterparty}
                    component={FormikDropDown}
                    disabled={readOnly}
                  />
                </FormRow>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Risk Scoring</CardTitle>
              </CardHeader>
              <CardBody>
                {isDDCTypeOther(props.values.ddcType) ? (
                  <Risk />
                ) : (
                  <RiskCalculator
                    templates={riskConfigTemplates}
                    ddcTypeId={props.values.ddcType.id}
                    domicileGroup={props.values.domicileGroup.id}
                    formikProps={props}
                    disabled={readOnly}
                  />
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>AML/CTF</CardTitle>
              </CardHeader>
              <CardBody>
                {isNewAmlCtfVersion ? (
                  <NewAMLCTFRiskCalculator
                    amlCtfOptions={amlCtfOptions}
                    formikProps={props}
                    readOnly={readOnly}
                    //newUBOList={}
                  />
                ) : (
                  <AMLCTFRiskCalculator
                    amlCtfOptions={amlCtfOptions}
                    formikProps={props}
                    readOnly={readOnly}
                  />
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Links</CardTitle>
              </CardHeader>
              <CardBody className={styles.cardBody}>
                <Links formikProps={props} readOnly={readOnly} />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Evaluation and Recommendation</CardTitle>
              </CardHeader>
              <CardBody className={styles.cardBody}>
                <FormRow label="Comments:">
                  <FastField
                    name="comments"
                    component={FormikTextArea}
                    placeholder={"Please enter relevant information "}
                    disabled={readOnly}
                  />
                </FormRow>
                <FormRow label="Reason for Change of Overall Rating:">
                  <FastField
                    name="reasonForRiskChange"
                    component={FormikTextArea}
                    placeholder={"Please enter relevant information "}
                    // Always enabled if DDC is ACTIVE, otherwise it inherits readOnly from general rules
                    disabled={ddcStatus !== "ACTIVE" && readOnly}
                  />
                </FormRow>
                <FormRow label="Recommendation by the Compliance Team:">
                  <FastField
                    name="recommendation"
                    options={options.recommendations}
                    component={FormikDropDown}
                    sideEffect={(oldValue: Option, newValue: Option) => {
                      if (
                        oldValue?.id !== newValue.id &&
                        !isRecommendationWithConditions(newValue)
                      ) {
                        // Reset Additional measures field when user selects
                        // any other recommendation option than one with options
                        props.setFieldValue("additionalMeasures", "", false);
                        props.setFieldTouched(
                          "additionalMeasures",
                          false,
                          false
                        );
                      }
                    }}
                    disabled={readOnly}
                  />
                </FormRow>
                {isRecommendationWithConditions(
                  props.values.recommendation
                ) && (
                  <FormRow label="Additional measures to be taken:">
                    <FastField
                      name="additionalMeasures"
                      component={FormikTextArea}
                      placeholder={"Please enter relevant information "}
                      disabled={readOnly}
                    />
                  </FormRow>
                )}
                <FormRow label="Reporting Date:">
                  <Field
                    name="submissionDate"
                    component={FormikDatePicker}
                    min={minSubmissionDate}
                    max={maxSubmissionDate}
                    disabled={readOnly}
                    sideEffect={(oldValue: Date, newValue: Date) => {
                      const [
                        minDate,
                        yearAwayDate,
                        twoYearsAwayDate,
                        threeYearsAwayDate,
                      ] = calculateDDDates(newValue);
                      minDDDate.current = minDate;
                      yearAwayDDDate.current = yearAwayDate;
                      twoYearsAwayDDDate.current = twoYearsAwayDate;
                      threeYearsAwayDDDate.current = threeYearsAwayDate;
                      fiveYearsAwayDDDate.current = fiveYearsAwayDate;
                      const nextReviewDate = calculateNextReviewDate(
                        props.values.frequency,
                        yearAwayDate,
                        twoYearsAwayDate,
                        threeYearsAwayDate,
                        fiveYearsAwayDate,
                        minDate
                      );
                      setImmediate(() => {
                        if (props.values.frequency.name === "Other" && props.values.nextDDDate) {
                          return;
                        }
                        props.setFieldValue(
                          "nextDDDate",
                          nextReviewDate,
                          false
                        );
                        props.setFieldTouched("nextDDDate", false, true);
                      });
                    }}
                  />
                </FormRow>
                <FormRow label="Next Review Due:">
                  <FastField
                    name="frequency"
                    options={options.frequencies}
                    component={FormikDropDown}
                    disabled={readOnly}
                    sideEffect={(oldValue: Option, newValue: Option) => {
                      if (oldValue?.id !== newValue.id) {
                        const frequency = newValue;
                        let nextDDDate = calculateNextReviewDate(
                          frequency,
                          yearAwayDDDate.current,
                          twoYearsAwayDDDate.current,
                          threeYearsAwayDDDate.current,
                          fiveYearsAwayDDDate.current,
                          minDDDate.current
                        );
                        props.setFieldValue("nextDDDate", nextDDDate, false);
                        props.setFieldTouched("nextDDDate", false, true);
                      }
                    }}
                  />
                </FormRow>
                {isOtherReviewDateSelected(props.values.frequency) && (
                  <FormRow label="Due Date:">
                    <Field
                      name="nextDDDate"
                      component={FormikDatePicker}
                      min={minDDDate.current}
                      max={maxDDDate.current}
                      disabled={readOnly}
                    />
                  </FormRow>
                )}
                <FormRow label="Ongoing DD:">
                  <Field
                    name="nextDDDate"
                    format="quartal"
                    component={FormikDateLabel}
                  />
                </FormRow>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Compliance Approval</CardTitle>
              </CardHeader>
              <CardBody className={styles.cardBody}>
                <div className={styles.signatureRow}>
                  <KendoError>
                    <ErrorMessage name="dueDiligenceConducted" />
                  </KendoError>
                  <div className={styles.confirmationRow}>
                    <Label editorId={"dueDiligenceConducted"}>
                      I hereby confirm to have conducted the due dilgence
                      accurately and the results shown in this checklist
                      adequately represent my findings.
                    </Label>
                    <FastField
                      name="dueDiligenceConducted"
                      component={FormikCheckbox}
                      disabled={readOnly || ddConfirmationState === "disabled"}
                    />
                  </div>
                </div>
                {amlConfirmationState !== "hidden" && (
                  <div className={styles.signatureRow}>
                    <KendoError>
                      <ErrorMessage name="worldCheckOneConducted" />
                    </KendoError>
                    <div className={styles.confirmationRow}>
                      <Label>
                        I hereby confirm that I have reviewed the due diligence
                        results with a focus on AML related information. Any
                        findings have been updated accordingly.
                      </Label>
                      <Field
                        name="worldCheckOneConducted"
                        component={FormikCheckbox}
                        disabled={isWcoDisabled(
                          readOnly,
                          amlConfirmationState === "disabled",
                          props.values,
                          amlCtfVersion
                          //isNewAmlCtfVersion
                        )}
                      />
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
            <div className={styles.controls}>
                <Button
                className={styles.submit}
                disabled={
                  ddcStatus === "ACTIVE" ? props.isSubmitting || !props.dirty || isSaving :
                   props.isSubmitting || !props.dirty || readOnly || isSaving
                }
                onClick={() => {
                  const formSubmission = ddcToRequest(props.values, amlCtfVersion);
                  setIsSaving(true);
                  save(formSubmission)
                    .then(() => {
                      showNotification({
                        uuid: uuidv4(),
                        type: "success",
                        text: "DDC SAVED",
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      const message = getErrorMessage(error);
                      showNotification({
                        uuid: uuidv4(),
                        type: "error",
                        text: message,
                      });
                    }).finally(() => setIsSaving(false));
                }}
              >
                {isSaving ? (
                  <span className="k-i-loading" />
                ) : (
                  "Save"
                )}
              </Button>
              <Button
                type="submit"
                className={styles.submit}
                disabled={
                  props.isSubmitting || readOnly || isSaving
                }
              >
                {props.isSubmitting ? (
                  <span className="k-i-loading" />
                ) : (
                  "Submit"
                )}
              </Button>
              {showCancel && (
                <Button
                  type="button"
                  className={styles.cancel}
                  disabled={props.isSubmitting || readOnly}
                  onClick={() => setShowCancelDialog(true)}
                >
                  {props.isSubmitting ? (
                    <span className="k-i-loading" />
                  ) : (
                    "Cancel"
                  )}
                </Button>
              )}
              {showRevert && (
                <Button
                  type="button"
                  className={styles.cancel}
                  disabled={props.isSubmitting || readOnly}
                  onClick={() => setShowRevertDialog(true)}
                >
                  {props.isSubmitting ? (
                    <span className="k-i-loading" />
                  ) : (
                    "Revert"
                  )}
                </Button>
              )}
            </div>
            {showCancelDialog && (
              <Dialog
                title={"Cancel DDC"}
                type="choice"
                onClose={() => {
                  setShowCancelDialog(false);
                }}
                onNegativeButtonClick={() => {
                  setShowCancelDialog(false);
                }}
                onPositiveButtonClick={() => {
                  setShowCancelDialog(false);
                  if (
                    areCommentsUpdated(
                      initialValues.comments,
                      props.values.comments
                    )
                  ) {
                    cancelDDC(
                      props.values,
                      props.setSubmitting,
                      isNewAmlCtfVersion
                    );
                  } else {
                    setShowLeaveCommentDialog(true);
                  }
                }}
              >
                Cancelling DDC is irreversible operation.
                <br />
                Are you sure you want to continue?
              </Dialog>
            )}
            {showLeaveCommentDialog && (
              <Dialog
                title={"Leave a comment"}
                type="confirmation"
                positiveButtonText="OK"
                onClose={() => {
                  setShowLeaveCommentDialog(false);
                }}
                onPositiveButtonClick={() => {
                  setShowLeaveCommentDialog(false);
                }}
              >
                Please leave a comment before cancelling the record.
              </Dialog>
            )}
            {showRevertDialog && (
              <Dialog
                title={"Revert DDC"}
                type="choice"
                onClose={() => {
                  setShowRevertDialog(false);
                }}
                onNegativeButtonClick={() => {
                  setShowRevertDialog(false);
                }}
                onPositiveButtonClick={() => {
                  setShowRevertDialog(false);
                  revertDDC(
                    props.values,
                    props.setSubmitting,
                    isNewAmlCtfVersion
                  );
                }}
              >
                Reverting DDC will move it back to DRAFT.
                <br />
                Are you sure you want to continue?
              </Dialog>
            )}
          </form>
        )}}
      </Formik>
      <Notifications
        notifications={notifications}
        hideNotification={hideNotification}
      />
    </div>
  );
};

export default DDCForm;
